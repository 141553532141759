import { useEffect, useState } from 'react';
import { useApolloClient } from '@apollo/client';
import { Button, Form, Input } from 'reactstrap';
import { errorMsg, successMsg } from '../../cache/vars';
import { DECLINE_SERVICE, GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO, GET_FILE_DOWNLOAD_LINK, SAVE_STAGE_ONE_CARD } from '../../queries/vip-workflow';
import { SERVICE_STATE } from '../../utils/constants';
import StagedCardLayout from '../StagedCard/StagedCardLayout/StagedCardLayout';
import CardDetails from '../StagedCard/CardDetails/CardDetails';
import CardGoogleMap from '../StagedCard/CardGoogleMap/CardGoogleMap';
import { CardGoogleAddress } from '../StagedCard/CardGoogleAddress/CardGoogleAddress';
import CardGuaranteed from '../StagedCard/CardGuaranteed/CardGuaranteed';
import CardAction from '../StagedCard/CardAction/CardAction';
import CardDeclineBtn from '../StagedCard/CardDeclineBtn/CardDeclineBtn';
import { generateMailtoLink, truncateString } from '../../utils/services';
import GetHelpModal from '../Modal/GetHelpModal';
import { TicketActions } from '../Modal/TicketActions';
import { TicketsPreviewModal } from '../Modal/TicketsPreviewModal';

import reservationIcon from '../../assets/img/icons/cards/reservation-icon.svg';
import ticketIcon from "../../assets/img/icons/common/ticket-icon.svg";
import flagIcon from '../../assets/img/icons/cards/flag-icon.svg';
import mapIcon from '../../assets/img/icons/cards/map-icon.svg';
import utencilsIcon from "../../assets/img/icons/cards/utencils-icon.svg";
import helpIcon from '../../assets/img/icons/cards/help-icon.svg';
import bellIcon from '../../assets/img/icons/cards/bell-icon.svg';

import './dinnerGroupCardNew.scss';

type DinnerGroupCardNewProps = {
  cart: any;
  datetime: any | null;
  time: any;
  isMissingInfo: boolean;
  isPublished: boolean;
  isServiceDeclined: boolean;
  serviceDeclined: string;
  packageDetailsId: number;
  setPackageOrderWithDetails: (data: any) => void;
  decryptedInvoiceId: any;
  setMissingInfoCards: any;
  cardDescription: string;
  cardGroup: string;
  packageOrderWithDetails: any;
  cardsWithTickets: Record<string, any>;
  ticketsForCard: Record<string, any>;
};

const DinnerGroupCardNew = ({
  cart,
  datetime,
  time,
  isMissingInfo,
  isPublished,
  isServiceDeclined,
  serviceDeclined,
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
  cardGroup,
  packageOrderWithDetails,
  cardsWithTickets,
  ticketsForCard,
}: DinnerGroupCardNewProps) => {
  const client = useApolloClient();
  
  const [file, setFile] = useState<string | undefined>("");
  const [ticket, setTicket] = useState<any | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [emailLink, setEmailLink] = useState("");
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);
  const [isInvalid, setIsInvalid] = useState(false);
  
  const [isFieldsValid, setIsFieldsValid] = useState({
    alternativeFirstName: true,
    alternativeLastName: true,
  });

  const [cardState, setCardState] = useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: { },
  });
  
  const handleDeclineClick = async () => {
    if (window.confirm("Are you sure you want to decline this service?")) {
      const { data } = await client.mutate({
        mutation: DECLINE_SERVICE,
        variables: { packageDetailsId: packageDetailsId },
      });

      if (data?.declineService) {
        setPackageOrderWithDetails((prev: any) => {
          return {
            ...prev,
            orderDetails: prev.orderDetails.map((item: any) => {
              return item.packageDetailsId === packageDetailsId
                ? {
                    ...item,
                    cartDetails: {
                      ...item.cartDetails,
                      cartId: data?.declineService,
                      isServiceDeclined: SERVICE_STATE.DECLINED,
                    },
                  }
                : item;
            }),
          };
        });
        successMsg("Service Declined");
      }
    }
  };
  
  useEffect(() => {
    const requiredFields = ['alternativeFirstName', 'alternativeLastName'];

    const isRequiredFieldsEmpty = requiredFields.some((el) => !cardState.cartData[el]);

    setIsSubmitBtnDisabled(isRequiredFieldsEmpty);
  }, [cardState.cartData]);
  
  useEffect(() => {
    generateMailtoLink(client, window.location.pathname, setEmailLink);
  }, [client]);

  const cartJsonData = JSON.parse(cart.cartDetails.cartJsonData);
  
  const tickets = ticketsForCard?.filter((ticket: any) => ticket.deliveryStatus === "UPLOADED" || ticket.eTicketLink);

  return (
    <StagedCardLayout
      cardIcon={utencilsIcon}
      cardTitle={cart?.cartDetails?.cardTitle || "Pre-show Hospitality"}
      datetime={datetime}
      time={time}
      isPublished={isPublished}
      isServiceDeclined={isServiceDeclined}
      serviceDeclined={serviceDeclined}
      isMissingInfo={isMissingInfo}
      cardGroup={cardGroup}
    >
      <>
        <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tag={cardGroup.replaceAll(' ', '')} />
        
        {isPublished &&
        
          <>
            <>
              <CardDetails
                cardTitle="Reservation info"
                cardIcon={bellIcon}
                cardSubtitle={`Reservation is for: ${packageOrderWithDetails.alternativeName ? packageOrderWithDetails.alternativeName : packageOrderWithDetails.recipient ? packageOrderWithDetails.recipient : packageOrderWithDetails.client}`}
              />
              
              <div className="dinner-card-content">
                {cartJsonData.qty &&
                  <div className="card-content-column">
                    <p>Table for {cartJsonData.qty}</p>
                  </div>
                }
                {(cartJsonData.alternativeFirstName || cartJsonData.alternativeLastName) &&
                  <>
                    <div className="dinner-card-content-alt-first-name">
                      <div className="card-content-column">
                        {cartJsonData?.alternativeFirstName && <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.alternativeFirstName}</p>}
                      </div>
                    </div>
                    <div className="dinner-card-content-alt-second-name">
                      <div className="card-content-column">
                        {cartJsonData?.alternativeLastName && <p style={{ fontSize: '14px', fontWeight: '400', fontFamily: "'Inter', sans-serif" }}>{cartJsonData?.alternativeLastName}</p>}
                      </div>
                    </div>
                  </>
                }
              </div>
                
              {cartJsonData.foodBeverageCredit && 
                <div className="dinner-card-content">
                  <>
                    <div className="card-content-column">
                      <p>Food & Beverage Credit Up To</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData.foodBeverageCredit}</p>
                    </div>
                  </>
                </div>
              }
              
              <div className="divider"></div>
            </>

            {(cartJsonData.restaurantName || cartJsonData?.restaurantPlaceName) &&
              <>
                <CardDetails
                  cardIcon={reservationIcon}
                  cardTitle={cartJsonData.restaurantName}
                  // cardTitle={<span clasName="card-title">Restaurant Name<span className="spacing"></span>{cartJsonData.restaurantName}</span>}
                  cardSubtitleGoogleLink={{
                    name: cartJsonData?.restaurantName === cartJsonData?.restaurantPlaceName ? truncateString(cartJsonData?.restaurantName, 30) : cartJsonData?.restaurantName,
                    address: !cartJsonData?.restaurantName ? truncateString(cartJsonData?.restaurantPlaceName, 30) : cartJsonData?.restaurantPlaceName,
                    link: cartJsonData?.restaurantPlaceValue ? cartJsonData.restaurantPlaceLink  :  `https://www.google.com/maps/search/?api=1&query=${encodeURIComponent([cartJsonData?.restaurantName, cartJsonData?.restaurantPlaceName].filter(Boolean).join(' '))}`,
                    time: cartJsonData?.date || ''
                  }}
                >
                  <div className="dinner-card-content">
                    {cartJsonData?.restaurantPhoneNumber &&
                      <a href={`tel:${cartJsonData?.restaurantPhoneNumber}`} className="phone-link">
                        {cartJsonData?.restaurantPhoneNumber.startsWith('+1') ? cartJsonData?.restaurantPhoneNumber.slice(2).trim() : cartJsonData?.restaurantPhoneNumber.trim()}
                      </a>
                    }
                  </div>
                  
                  <CardGoogleAddress address={cartJsonData?.restaurantPlaceName} className="pl-4" />
                  
                  <CardGoogleMap address={cartJsonData?.restaurantPlaceName} className="pl-4" />
                </CardDetails>
                          
                <div className="divider"></div>
              </>
            }
            

            
            {cartJsonData?.description &&
              <>
                <CardDetails
                  cardIcon={flagIcon}
                  cardTitle={cartJsonData.description}
                  titleStyle={{ fontSize: '16px', fontWeight: '400', fontFamily: "'Inter', sans-serif", color: '#5F6778' }}
                />
              
                <div className="divider"></div>
              </>
            }
            
            {!!tickets.length &&
              <>
                <CardDetails
                  cardIcon={ticketIcon}
                  cardTitle="Access Tickets now"
                  children={
                    <div className="mt-4">
                      {ticketsForCard && ticketsForCard.length ? (
                        ticketsForCard.map((ticket: any, index: number) => (
                          <TicketActions
                            ticket={ticket}
                            setFile={setFile}
                            setTicket={setTicket}
                            setIsModalOpened={setIsModalOpened}
                          />
                        ))
                      ) : (
                        <p>No tickets available</p>
                      )}
                    </div>
                  }
                />
                <div className="divider"></div>
              </>
            }
            
            {cartJsonData?.notes &&
              <>
                <CardDetails
                  cardIcon={flagIcon}
                  cardTitle="Additional Notes"
                  cardDescription={cartJsonData.notes}
                />
                
                <div className="divider"></div>
              </>
            }
            
            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        }

        {!isPublished && isMissingInfo && (
          <>
            <CardDetails
              isEditable={true}
              cardIcon={reservationIcon}
              cardTitle="Pre-show Hospitality"
              cardSubtitle={
                <>
                  {
                    packageOrderWithDetails.recipient ? (
                      <span style={{ color: '#D13053' }}>
                        Please provide an alternative name only if it differs from {packageOrderWithDetails.recipient}. Otherwise, confirm this information.
                      </span>
                    ) : (
                      <span style={{ color: '#D13053' }}>
                        Please provide an alternative name only if it differs from the name of your order. Otherwise, confirm this information.
                      </span>
                    )
                  }
                  <Button className="btn-round btn-icon d-flex align-items-center mt-1 confirm-btn" type="button" 
                    onClick={async (e) => {
                      e.preventDefault();
                      const { data } = await client.mutate({
                        mutation: SAVE_STAGE_ONE_CARD,
                        variables: { editCartDetailsInput: {
                          ...cardState,
                          cartData: {
                            ...cardState.cartData,
                            alternativeName: packageOrderWithDetails.recipient
                          }}}
                      });
                      
                      const filtersData = await client.query({
                        query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
                        variables: { invoiceId: decryptedInvoiceId },
                        fetchPolicy: "no-cache",
                      });

                      if (data) {
                        setPackageOrderWithDetails((prev: any) => {
                          return {
                            ...prev,
                            orderDetails: [
                              ...prev.orderDetails.map((cart: any) => {
                                return cart.packageDetailsId === packageDetailsId
                                  ? {
                                      ...cart,
                                      cartDetails: {
                                        ...cart.cartDetails,
                                        cardTitle: data?.saveStageOneCard.cardTitle,
                                        cartId: data?.saveStageOneCard.cartId,
                                        cartJsonData: data?.saveStageOneCard.cartJsonData,
                                        isPublished: data?.saveStageOneCard.isPublished,
                                        packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                                      },
                                    }
                                  : cart;
                              }),
                            ],
                          };
                        });
                        
                        setMissingInfoCards(
                          filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
                        );

                        successMsg("Confirmed");
                      }
                    }}
                  >
                  Confirm
                  </Button>
                </>
              }
            >
              <>
                
                <div className="divider"></div>
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    
                    const { data } = await client.mutate({
                      mutation: SAVE_STAGE_ONE_CARD,
                      variables: { editCartDetailsInput: {
                        ...cardState,
                        cartData: {
                          ...cardState.cartData,
                          alternativeName: (`${cardState.cartData?.alternativeFirstName || ''} ${cardState.cartData?.alternativeLastName || ''}`)?.trim()
                        },
                      } },
                    });
                    
                    const filtersData = await client.query({
                      query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
                      variables: { invoiceId: decryptedInvoiceId },
                      fetchPolicy: "no-cache",
                    });

                    if (data) {
                      setPackageOrderWithDetails((prev: any) => {
                        return {
                          ...prev,
                          orderDetails: [
                            ...prev.orderDetails.map((cart: any) => {
                              return cart.packageDetailsId === packageDetailsId
                                ? {
                                    ...cart,
                                    cartDetails: {
                                      ...cart.cartDetails,
                                      cardTitle: data?.saveStageOneCard.cardTitle,
                                      cartId: data?.saveStageOneCard.cartId,
                                      cartJsonData: data?.saveStageOneCard.cartJsonData,
                                      isPublished: data?.saveStageOneCard.isPublished,
                                      packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                                    },
                                  }
                                : cart;
                            }),
                          ],
                        };
                      });
                      
                      setMissingInfoCards(
                        filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
                      );

                      successMsg("Saved");
                    }
                  }}
                >
                  <div className="d-flex flex-row inputs-container">
                    <div className="input-container">
                      <label>Alternative First Name</label>
                      <div className="input-box">
                        <Input
                          type="text"
                          placeholder="Alternative First Name"
                          name="alternativeFirstName"
                          value={cardState.cartData.alternativeFirstName}
                          onChange={(e) => {
                            const value = e.target.value;
                            const hasNumber = /\d/.test(value);
                        
                            if (hasNumber) {
                              setIsInvalid(true);
                            } else {
                              setIsInvalid(false);
                            }
                        
                            if (!hasNumber) {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                                };
                              });
                            }
                          }}
                        />
                        {isInvalid && <p className="number-validation" style={{ marginTop: '1rem' }}>Numbers is not allowed</p>}
                      </div>
                    </div>
                    <div className="input-container">
                      <label>Alternative Last Name</label>
                      <div className="input-box">
                        <Input
                          type="text"
                          placeholder="Alternative Last Name"
                          name="alternativeLastName"
                          value={cardState.cartData.alternativeLastName}
                          onChange={(e) => {
                            const value = e.target.value;
                            const hasNumber = /\d/.test(value);
                        
                            if (hasNumber) {
                              setIsInvalid(true);
                            } else {
                              setIsInvalid(false);
                            }
                        
                            if (!hasNumber) {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: { ...prev.cartData, [e.target.name]: e.target.value },
                                };
                              });
                            }
                          }}
                        />
                        {isInvalid && <p className="number-validation" style={{ marginTop: '1rem' }}>Numbers is not allowed</p>}
                      </div>
                    </div>
                  </div>
                  <div className="actions-container">
                    {!isServiceDeclined && <CardDeclineBtn handleClick={handleDeclineClick} />}
                    <Button disabled={isSubmitBtnDisabled} className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            </CardDetails>
            
            <div className="divider"></div>
            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        )}
        
        {!isPublished && !isMissingInfo &&
          <>
            <CardDetails
              isToggleExpanded={false}
              cardIcon={reservationIcon}
              cardTitle="Dinner"
            >
              <div className="pl-4 mb-4">
                <CardGuaranteed text={isServiceDeclined && serviceDeclined !== 'UNDECLINED' ? "You’ve declined this service." : "Details will be provided as the event approaches."} subtext={isServiceDeclined ? "If you need further assistance please use the Get Help button." : ""} />
                {!isServiceDeclined &&
                  <>
                    <div className="divider"></div>

                    <div className="actions-container">
                      <CardDeclineBtn handleClick={handleDeclineClick} />
                    </div>
                  </>
                }
                <div className="divider"></div> 
                
                <CardAction icon={helpIcon} text="Get help" 
                  handleClick={() => {
                  setIsModalOpen(true)
                }}
            />
              </div>
            </CardDetails>
          
            {cartJsonData?.notes &&
              <CardDetails
                cardIcon={flagIcon}
                cardTitle="Additional Notes"
                cardDescription={cartJsonData.notes}
              />
            }
          </>
        }
        <TicketsPreviewModal
          isModalOpened={isModalOpened}
          setIsModalOpened={setIsModalOpened}
          file={file}
          ticket={ticket}
        />
      </>
    </StagedCardLayout>
  );
};

export default DinnerGroupCardNew;