import { useEffect, useState } from 'react';
import moment from 'moment';
import { useApolloClient } from '@apollo/client';
import { Button, Form, Input } from 'reactstrap';
import { errorMsg, successMsg } from '../../cache/vars';
import { DECLINE_SERVICE, GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO, GET_FILE_DOWNLOAD_LINK, SAVE_STAGE_ONE_CARD } from '../../queries/vip-workflow';
import { SERVICE_STATE } from '../../utils/constants';
import StagedCardLayout from '../StagedCard/StagedCardLayout/StagedCardLayout';
import CardDetails from '../StagedCard/CardDetails/CardDetails';
import GooglePlaceAutocompleteInput from '../GooglePlaceAutocomplete/GooglePlaceAutocomplete';
import CardDeclineBtn from '../StagedCard/CardDeclineBtn/CardDeclineBtn';
import CardGoogleMap from '../StagedCard/CardGoogleMap/CardGoogleMap';
import CardGuaranteed from '../StagedCard/CardGuaranteed/CardGuaranteed';
import CardAction from '../StagedCard/CardAction/CardAction';
import { generateMailtoLink, truncateString } from '../../utils/services';
import GetHelpModal from '../Modal/GetHelpModal';
import reservationIcon from '../../assets/img/icons/cards/reservation-icon.svg';
import ticketIcon from "../../assets/img/icons/common/ticket-icon.svg";
import flagIcon from '../../assets/img/icons/cards/flag-icon.svg';
import partyPooperIcon from '../../assets/img/icons/cards/party-pooper-icon.svg';
import planeIcon from '../../assets/img/icons/cards/plane-icon.svg';
import helpIcon from '../../assets/img/icons/cards/help-icon.svg';
import carIcon from '../../assets/img/icons/cards/car-icon.svg';
import mapIcon from '../../assets/img/icons/cards/map-icon.svg';

import './vegasLimoTransferArrivalGroupCardNew.scss';
import { TicketActions } from '../Modal/TicketActions';
import { TicketsPreviewModal } from '../Modal/TicketsPreviewModal';
import { PhoneInput } from '../Inputs/PhoneInput';

type VegasLimoTransferArrivalGroupCardNewProps = {
  cart: any;
  datetime: any | null;
  time: string;
  isMissingInfo: boolean;
  isPublished: boolean;
  isServiceDeclined: boolean;
  serviceDeclined: string;
  packageDetailsId: number;
  setPackageOrderWithDetails: (data: any) => void;
  decryptedInvoiceId: any;
  setMissingInfoCards: any;
  cardDescription: string;
  cardGroup: string;
  cardsWithTickets: Record<string, any>;
  ticketsForCard: Record<string, any>;
};

const VegasLimoTransferArrivalGroupCardNew = ({
  cart,
  datetime,
  time,
  isMissingInfo,
  isPublished,
  isServiceDeclined,
  serviceDeclined,
  packageDetailsId,
  setPackageOrderWithDetails,
  decryptedInvoiceId,
  setMissingInfoCards,
  cardDescription,
  cardGroup,
  cardsWithTickets,
  ticketsForCard,
}: VegasLimoTransferArrivalGroupCardNewProps) => {
  const client = useApolloClient();
  
  const [file, setFile] = useState<string | undefined>("");
  const [ticket, setTicket] = useState<any | undefined>();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isModalOpened, setIsModalOpened] = useState(false);
  const [selectedFile, setSelectedFile] = useState<string | undefined>("");
  const [emailLink, setEmailLink] = useState("");
  const [cardState, setCardState] = useState<{ packageDetailsId: number; cartData: any }>({
    packageDetailsId,
    cartData: {},
  });
  const [isSubmitBtnDisabled, setIsSubmitBtnDisabled] = useState(true);

  const [isFieldsValid, setIsFieldsValid] = useState({
    contactName: true,
    contactNumber: true,
    pickUpName: true,
    pickUpAddress: true,
  });
  
  const handleDeclineClick = async () => {
    if (window.confirm("Are you sure you want to decline this service?")) {
      const { data } = await client.mutate({
        mutation: DECLINE_SERVICE,
        variables: { packageDetailsId: packageDetailsId },
      });

      if (data?.declineService) {
        setPackageOrderWithDetails((prev: any) => {
          return {
            ...prev,
            orderDetails: prev.orderDetails.map((item: any) => {
              return item.packageDetailsId === packageDetailsId
                ? {
                    ...item,
                    cartDetails: {
                      ...item.cartDetails,
                      cartId: data?.declineService,
                      isServiceDeclined: SERVICE_STATE.DECLINED,
                    },
                  }
                : item;
            }),
          };
        });
        successMsg("Service Declined");
      }
    }
  };
  
  useEffect(() => {
    const requiredFields = [
      "contactName",
      "contactNumber",
      "pickUpName",
      "pickUpAddress",
    ];
    
    const isRequiredFieldsEmpty = requiredFields.some((el) => {
      return !cardState.cartData[el];
    }) || cardState.cartData['contactNumber'] === '+' || cardState.cartData['contactNumber'].length <= 6;

    setIsSubmitBtnDisabled(isRequiredFieldsEmpty);
  }, [cardState.cartData]);
  
  useEffect(() => {
    generateMailtoLink(client, window.location.pathname, setEmailLink);
  }, [client]);
  
  const cartJsonData = JSON.parse(cart.cartDetails.cartJsonData);
  
  const tickets = ticketsForCard?.filter((ticket: any) => ticket.deliveryStatus === "UPLOADED" || ticket.eTicketLink);
  
  return (
    <StagedCardLayout
      cardIcon={partyPooperIcon} 
      cardTitle={cart?.cartDetails?.cardTitle || "Vegas Limo Transfer Arrival"}
      datetime={datetime}
      time={time}
      isPublished={isPublished} 
      isServiceDeclined={isServiceDeclined}
      serviceDeclined={serviceDeclined}
      isMissingInfo={isMissingInfo}
      cardGroup={cardGroup}
    >
      <>
      <GetHelpModal isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} tag={cardGroup.replaceAll(' ', '')} />

        {isPublished &&
          <>
            <CardDetails
              cardIcon={carIcon}
              cardTitle="Vendor Name"
              cardSubtitle={cartJsonData?.vendorName}
            >
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData?.vendorPhone &&
                  <>
                    <div className="card-content-column">
                      <p>Vendor Phone</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData?.vendorPhone.startsWith('+1') ? cartJsonData?.vendorPhone.slice(2).trim() : cartJsonData?.vendorPhone.trim()}</p>
                    </div>
                  </>
                }
              </div>
            </CardDetails>
            
            <div className="divider"></div>
            
            <CardDetails
              cardIcon={mapIcon}
              cardTitle="Pick Up At"
              cardSubtitleGoogleLink={{
                name: cartJsonData?.pickUpName === cartJsonData?.pickUpPlaceName ? truncateString(cartJsonData?.pickUpName, 30) : cartJsonData?.pickUpName,
                address: !cartJsonData?.pickUpName ? truncateString(cartJsonData?.pickUpPlaceName, 30) : cartJsonData?.pickUpPlaceName,
                link: cartJsonData?.pickUpPlaceLink, 
                time: cartJsonData?.pickUp || ''
              }}
            >
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData?.contactName &&
                  <>
                    <div className="card-content-column">
                      <p>Contact Name</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData?.contactName}</p>
                    </div>
                  </>
                }
              </div>
              
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData?.contactNumber &&
                  <>
                    <div className="card-content-column">
                      <p>Contact Number</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData?.contactNumber.startsWith('+1') ? cartJsonData?.contactNumber.slice(2).trim() : cartJsonData?.contactNumber.trim()}</p>
                    </div>
                  </>
                }
              </div>
              <CardGoogleMap address={cartJsonData?.pickUpPlaceName || cartJsonData?.pickUpAddress} className="pl-4" />
            </CardDetails>
            
            <div className="divider"></div>
            
            <CardDetails
              cardIcon={mapIcon}
              cardTitle="Transporting To"
              cardSubtitleGoogleLink={{
                name: cartJsonData?.dropOffName === cartJsonData?.dropOffPlaceName ? truncateString(cartJsonData?.dropOffName, 30) : cartJsonData?.dropOffName,
                address: !cartJsonData?.dropOffName ? truncateString(cartJsonData?.dropOffPlaceName, 30) : cartJsonData?.dropOffPlaceName,
                link: cartJsonData?.dropOffPlaceLink, 
                time: cartJsonData?.date || ''
              }}
            >   
              <div className="vegas-limo-transfer-card-content">
                {cartJsonData?.celebration &&
                  <>
                    <div className="card-content-column">
                      <p>Celebration</p>
                    </div>
                    <div className="card-content-column">
                      <p>{cartJsonData?.celebration}</p>
                    </div>
                  </>
                }
              </div>
              <CardGoogleMap address={cartJsonData?.dropOffAddress} className="pl-4" />
            </CardDetails>
            
            {!!tickets.length &&
              <>
                <div className="divider"></div>
                <CardDetails
                  cardIcon={ticketIcon}
                  cardTitle="Access Tickets now"
                  children={
                    <div className="mt-4">
                      {ticketsForCard && ticketsForCard.length ? (
                        ticketsForCard.map((ticket: any, index: number) => (
                          <TicketActions
                            ticket={ticket}
                            setFile={setFile}
                            setIsModalOpened={setIsModalOpened}
                            setTicket={setTicket}
                          />
                        ))
                      ) : (
                        <p>No tickets available</p>
                      )}
                    </div>
                  }
                />
              </>
            }
            
            <div className="divider"></div>
            
            {cartJsonData?.notes &&
              <>
                <CardDetails
                  cardIcon={flagIcon}
                  cardTitle="Additional Notes"
                  cardDescription={cartJsonData.notes}
                />
                
                <div className="divider"></div>
              </>
            }
            
            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        }

        {!isPublished && isMissingInfo && (
          <>
            <CardDetails
              isEditable={true}
              cardIcon={planeIcon}
              cardTitle="Arrival Details"
              cardSubtitle="Please provide your info"
            >
              <>
                <p className="mt-4 mr-4 mb-0 ml-2">
                  Provide us with your arrival flight information. Transfers are reserved in a vehicle accommodating the size of your party associated to the package. Special vehicle types and additional stops will require an additional charge.
                </p>
                
                <div className="divider"></div>
                
                <div className="mb-4">
                  <p>Contact Details</p>
                  <p className="required-note">* These fields are required.</p>
                </div>
                
                <Form
                  onSubmit={async (e) => {
                    e.preventDefault();
                    const requiredFields = [
                      "pickUpName",
                      "pickUpAddress",
                      "contactName",
                      "contactNumber",
                    ];

                    const isRequiredFieldsEmpty = requiredFields.some((el) => {
                      return !cardState.cartData[el];
                    });

                    if (isRequiredFieldsEmpty) {
                      setIsFieldsValid({
                        contactName: !!cardState.cartData.contactName,
                        contactNumber: !!cardState.cartData.contactNumber,
                        pickUpName: !!cardState.cartData.pickUpName,
                        pickUpAddress: !!cardState.cartData.pickUpAddress,
                      });

                      errorMsg("Required Fields Should Not Be Empty");

                      return;
                    }

                    const { data } = await client.mutate({
                      mutation: SAVE_STAGE_ONE_CARD,
                      variables: { editCartDetailsInput: cardState },
                    });

                    const filtersData = await client.query({
                      query: GET_AVAILABLE_FILTERS_FOR_PACKAGE_INFO,
                      variables: { invoiceId: decryptedInvoiceId },
                      fetchPolicy: "no-cache",
                    });

                    if (data) {
                      setPackageOrderWithDetails((prev: any) => {
                        return {
                          ...prev,
                          orderDetails: [
                            ...prev.orderDetails.map((cart: any) => {
                              return cart.packageDetailsId === packageDetailsId
                                ? {
                                    ...cart,
                                    cartDetails: {
                                      ...cart.cartDetails,
                                      cardTitle: data?.saveStageOneCard.cardTitle,
                                      cartId: data?.saveStageOneCard.cartId,
                                      cartJsonData: data?.saveStageOneCard.cartJsonData,
                                      isPublished: data?.saveStageOneCard.isPublished,
                                      packageDetailsId: data?.saveStageOneCard.packageDetailsId,
                                    },
                                  }
                                : cart;
                            }),
                          ],
                        };
                      });
                      
                      setMissingInfoCards(
                        filtersData.data?.getAvailableFiltersForPackageInfo.showMissingInfoFor,
                      );

                      successMsg("Saved");
                    }
                  }}
                >
                    <div className="inputs-container">
                        <div className="input-container">
                        <label className={!isFieldsValid.contactName ? "label-invalid" : ""}>
                            Contact Name
                        </label>
                        <div className="input-box">
                          <Input
                          className={!isFieldsValid.contactName ? "input-invalid" : ""}
                          type="text"
                          placeholder="Contact Name"
                          name="contactName"
                          invalid={!isFieldsValid.contactName}
                          onChange={(e) => {
                              setCardState((prev: any) => {
                              return {
                                  ...prev,
                                  cartData: {
                                  ...prev.cartData,
                                  [e.target.name]: e.target.value,
                                  },
                              };
                              });
                          }}
                          value={cardState.cartData.contactName || ""}
                          />
                          <span></span>
                        </div>
                        </div>
                        <div className="input-container">
                        <label className={!isFieldsValid.contactNumber ? "label-invalid" : ""}>
                          Contact Number
                        </label>
                        <div className="input-box">
                          <PhoneInput    
                            className={!isFieldsValid.contactNumber ? "input-invalid" : ""}                
                            placeholder="+1 234-567-8999-9999" 
                            value={cardState?.cartData?.contactNumber || ''}
                            onChange={(value: any) => {
                              if (setCardState) {
                                setCardState((prev) => ({
                                  ...prev,
                                  cartData: {
                                    ...prev.cartData,
                                    contactNumber: value,
                                  },
                                }));
                              }
                            }}
                          />
                          <span></span>
                        </div>
                        </div>{" "}
                        <div className="input-container">
                        <label className={!isFieldsValid.pickUpName ? "label-invalid" : ""}>Pick-up Name</label>
                        <div className="input-box">
                            <Input
                            className={!isFieldsValid.pickUpName ? "input-invalid" : ""}
                            type="text"
                            placeholder="Pick-up Name"
                            name="pickUpName"
                            invalid={!isFieldsValid.pickUpName}
                            onChange={(e) => {
                                setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                  ...prev.cartData,
                                  [e.target.name]: e.target.value,
                                  },
                                };
                                });
                            }}
                            value={cardState.cartData.pickUpName || ""}
                            />
                            <span></span>
                        </div>
                        </div>{" "}
                        <div className="input-container">
                        <label className={!isFieldsValid.pickUpAddress ? "label-invalid": ""}>Pick-up Address</label>
                        <div className="input-box">
                          <GooglePlaceAutocompleteInput
                            cardState={cardState}
                            setCardState={setCardState}
                            placeName={"pickUpAddress"}
                            placeValueName={"pickUpAddressValue"}
                            placeLinkName={"pickUpPlaceLink"}
                          />
                          <span></span>
                        </div>
                        </div>{" "}
                        <div className="input-container">
                        <label>Celebration</label>
                        <div className="input-box">
                            <Input
                            type="text"
                            placeholder="Are you celebrating a special occasion?"
                            name="celebration"
                            onChange={(e) => {
                              setCardState((prev: any) => {
                                return {
                                  ...prev,
                                  cartData: {
                                  ...prev.cartData,
                                  [e.target.name]: e.target.value,
                                  },
                                };
                              });
                            }}
                            value={cardState.cartData.celebration || ""}
                            />
                            <span></span>
                        </div>
                        </div>{" "}
                    </div>
                  
                  <div className="actions-container">
                    {!isServiceDeclined && <CardDeclineBtn handleClick={handleDeclineClick} />}
                    <Button disabled={isSubmitBtnDisabled} className="btn-round btn-icon d-flex align-items-center save-btn" type="submit">
                      Save
                    </Button>
                  </div>
                </Form>
              </>
            </CardDetails>

            <div className="divider"></div> 

            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        )}

        {!isPublished && !isMissingInfo &&
          <>
            <CardDetails
              isToggleExpanded={false}
              cardIcon={reservationIcon}
              cardTitle="Departure Reservation Details"
            >
              <>
                <CardGuaranteed text={isServiceDeclined && serviceDeclined !== 'UNDECLINED' ? "You’ve declined this service." : "Details will be provided as the event approaches."} subtext={isServiceDeclined ? "If you need further assistance please use the Get Help button." : ""} />
                {!isServiceDeclined &&
                  <>
                    <div className="divider"></div>

                    <div className="actions-container">
                      <CardDeclineBtn handleClick={handleDeclineClick} />
                    </div>
                  </>
                }
              </>
            </CardDetails>
            
            <div className="divider"></div>

            <CardAction icon={helpIcon} text="Get help" 
              handleClick={() => {
                setIsModalOpen(true)
              }}
            />
          </>
        }
        
        <TicketsPreviewModal
          isModalOpened={isModalOpened}
          setIsModalOpened={setIsModalOpened}
          file={file}
          ticket={ticket}
        />
      </>
    </StagedCardLayout>
  );
};

export default VegasLimoTransferArrivalGroupCardNew;